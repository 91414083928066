import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import logo from '../../logo.svg';
import './Timetable.css'
import { Row, Col, Input, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, ButtonGroup } from 'reactstrap';

class Timetable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			month: 1,
			month_text: 'January',
			times: [],
			edit_time_data: {},
			edit_time_modal: false,
			jumuah_time_model: false,
			jumuah_times: [],
			redirect: false,
			user_id: sessionStorage.getItem('ptdb-user_id'),
			token: sessionStorage.getItem('ptdb-token'),
			org_prefix: sessionStorage.getItem('ptdb-org_prefix'),
			print_link: 'https://prayertimedb.com/' + sessionStorage.getItem('ptdb-org_prefix') + '/timetable/?month=1'
		}
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		if(sessionStorage.getItem('ptdb-token')) {
			axios.get('https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/month/' + this.state.month).then((response) => {
				this.setState({ times: response.data });
			})
			axios.get('https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/jumuah')
			.then((response) => {
				this.setState({ jumuah_times: response.data });
			})
		} else {
			this.setState({ redirect: true });
		}
	}

	logout() {
		sessionStorage.setItem('ptdb-user_id', '');
		sessionStorage.setItem('ptdb-token', '');
		sessionStorage.setItem('ptdb-org_prefix', '');
		sessionStorage.clear();
		this.setState({ redirect: true });
	}

	changeMonth = (month) => {
		let m_text = '';
		if(month < 10) {
			m_text = moment("0" + String(month)).format('MMMM') 
		} else {
			m_text = moment(String(month)).format('MMMM')
		}
		this.setState({
			month: month,
			month_text: m_text,
			print_link: 'https://prayertimedb.com/' + this.state.org_prefix + '/timetable/?month=' + month
		});
		axios.get('https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/month/' + month).then((response) => {
			this.setState({ times: response.data })
		});
	}

	toggle_edit_time_modal = () => {
		this.setState({ edit_time_modal: !this.state.edit_time_modal });
		this._refreshTimes();
	}

	toggle_edit_jumuah_modal = () => {
		this.setState({ jumuah_time_model: !this.state.jumuah_time_model });
		this._refreshTimes();
	}

	editTimes = (time) => {
		this.setState({
			oldTimeData: time,
			edit_time_data: time,
			edit_time_modal: !this.state.edit_time_modal
		});
	}

	updateJumuahTimes() {
		let { jumuah_times } = this.state;
		axios({
			method: 'put',
			url: 'https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/jumuah',
			headers: {
				Authorization: this.state.token,
				User: this.state.user_id
			},
			data: jumuah_times
		}).then((response) => {
			this.setState({ jumuah_time_model: false });
			this._refreshTimes();
		}).catch(error => {
			console.log(error)
		});
	}

	updateTimes() {
		let { fajr_begin, fajr_jammat, sunrise, dhuhr_begin, dhuhr_jammat, asr_begin, asr_jammat, maghrib, isha_begin, isha_jammat } = this.state.edit_time_data;
		debugger
		axios({
			method: 'put',
			url: 'https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/' + this.state.edit_time_data.id,
			headers: {
				Authorization: this.state.token,
				User: this.state.user_id
			},
			data: {
				fajr_begin: fajr_begin,
				fajr_jammat: fajr_jammat,
				sunrise: sunrise,
				dhuhr_begin: dhuhr_begin,
				dhuhr_jammat: dhuhr_jammat,
				asr_begin: asr_begin,
				asr_jammat: asr_jammat,
				maghrib: maghrib,
				isha_begin: isha_begin,
				isha_jammat: isha_jammat
			}
		}).then((response) => {
			this.setState({ edit_time_modal: false });
			this._refreshTimes();
		}).catch(error => {
			console.log(error)
		});
	}

	_refreshTimes() {
		axios.get('https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/month/' + this.state.month).then((response) => {
			this.setState({ times: response.data })
		})
		axios.get('https://api.prayertimedb.com/v1/' + this.state.org_prefix + '/time/jumuah')
		.then((response) => {
			this.setState({ jumuah_times: response.data });
		})
	}

	render() {
		if (this.state.redirect) {
			return (<Redirect to={'/login'}/>)
		}

		let times = this.state.times.map((time) => {
			return (
				<tr key={time.id}>
					<td>{time.day}</td>
					<td>{time.fajr_begin}</td>
					<td>{time.fajr_jammat}</td>
					<td>{time.sunrise}</td>
					<td>{time.dhuhr_begin}</td>
					<td>{time.dhuhr_jammat}</td>
					<td>{time.asr_begin}</td>
					<td>{time.asr_jammat}</td>
					<td>{time.maghrib}</td>
					<td>{time.isha_begin}</td>
					<td>{time.isha_jammat}</td>
					<td><Button color="success" size="sm" onClick={this.editTimes.bind(this, time)}>Edit</Button></td>
				</tr>
			);
		});
		let jumuah_times = this.state.jumuah_times.map((jumuah_time) => {
			return (
				<Row className="mb-2" key={jumuah_time.id}>
					<Col>
						<Input value={jumuah_time.location} disabled />
					</Col>
					<Col>
						<Input id={jumuah_time.id} defaultValue={jumuah_time.time} onChange={(e) => {
							this.state.jumuah_times.map((time) => {
								if(time.id === jumuah_time.id) {
									time.time = e.target.value;
								}
								return null;
							});
						}} />
					</Col>
				</Row>
			);
		});
		return (
			<div className="Timetable container">

				<Modal isOpen={this.state.jumuah_time_model}>
					<ModalHeader>Edit Jumuah Times</ModalHeader>
					<ModalBody>
						<Row>
							<Col>
								<Label className="font-weight-bold">Location</Label>
							</Col>
							<Col>
								<Label className="font-weight-bold">Time</Label>
							</Col>
						</Row>
						{jumuah_times}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.updateJumuahTimes.bind(this)}>Update Times</Button>{' '}
						<Button color="secondary" onClick={this.toggle_edit_jumuah_modal}>Cancel</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.edit_time_modal}>
					<ModalHeader>Edit Prayer Times - {this.state.edit_time_data.day}/{this.state.edit_time_data.month}</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Row>
								<Col>
									<Label for="fajr_begin">Fajr Begin</Label>
									<Input id="fajr_begin" value={this.state.edit_time_data.fajr_begin} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.fajr_begin = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col>
									<Label for="fajr_jammat">Fajr Jammat</Label>
									<Input id="fajr_jammat" value={this.state.edit_time_data.fajr_jammat} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.fajr_jammat = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Label for="sunrise" className="mt-3">Sunrise</Label>
									<Input id="sunrise" value={this.state.edit_time_data.sunrise} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.sunrise = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col></Col>
							</Row>
							<Row>
								<Col>
									<Label for="dhuhr_begin" className="mt-3">Dhuhr Begin</Label>
									<Input id="dhuhr_begin" value={this.state.edit_time_data.dhuhr_begin} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.dhuhr_begin = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col>
									<Label for="dhuhr_jammat" className="mt-3">Dhuhr Jammat</Label>
									<Input id="dhuhr_jammat" value={this.state.edit_time_data.dhuhr_jammat} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.dhuhr_jammat = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Label for="asr_begin" className="mt-3">Asr Begin</Label>
									<Input id="asr_begin" value={this.state.edit_time_data.asr_begin} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.asr_begin = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col>
									<Label for="asr_jammat" className="mt-3">Asr Jammat</Label>
									<Input id="asr_jammat" value={this.state.edit_time_data.asr_jammat} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.asr_jammat = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Label for="maghrib" className="mt-3">Maghrib</Label>
									<Input id="maghrib" value={this.state.edit_time_data.maghrib} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.maghrib = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col></Col>
							</Row>
							<Row>
								<Col>
									<Label for="isha_begin" className="mt-3">Isha Begin</Label>
									<Input id="isha_begin" value={this.state.edit_time_data.isha_begin} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.isha_begin = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
								<Col>
									<Label for="isha_jammat" className="mt-3">Isha Jammat</Label>
									<Input id="isha_jammat" value={this.state.edit_time_data.isha_jammat} onChange={(e) => {
										let { edit_time_data } = this.state;
										edit_time_data.isha_jammat = e.target.value;
										this.setState({ edit_time_data });
									}} />
								</Col>
							</Row>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.updateTimes.bind(this)}>Update Times</Button>{' '}
						<Button color="secondary" onClick={this.toggle_edit_time_modal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>

				<img className="timetable-logo" src={logo} alt="Logo" />
				<Button className="float-right" color="link" onClick={this.logout}>Logout</Button>
				<Button className="float-right" color="link" onClick={()=> window.open(this.state.print_link, "_blank")}>Print</Button>
				<Button className="float-right" color="link" onClick={this.toggle_edit_jumuah_modal}>Jumu'ah</Button>

				<ButtonGroup className="month-buttons">
					<Button onClick={this.changeMonth.bind(this, 1)}>January</Button>
					<Button onClick={this.changeMonth.bind(this, 2)}>Febuary</Button>
					<Button onClick={this.changeMonth.bind(this, 3)}>March</Button>
					<Button onClick={this.changeMonth.bind(this, 4)}>April</Button>
					<Button onClick={this.changeMonth.bind(this, 5)}>May</Button>
					<Button onClick={this.changeMonth.bind(this, 6)}>June</Button>
					<Button onClick={this.changeMonth.bind(this, 7)}>July</Button>
					<Button onClick={this.changeMonth.bind(this, 8)}>August</Button>
					<Button onClick={this.changeMonth.bind(this, 9)}>September</Button>
					<Button onClick={this.changeMonth.bind(this, 10)}>October</Button>
					<Button onClick={this.changeMonth.bind(this, 11)}>November</Button>
					<Button onClick={this.changeMonth.bind(this, 12)}>December</Button>
				</ButtonGroup>

				<h3>{this.state.org_prefix ? this.state.org_prefix.toUpperCase() : ''} Times <small>{this.state.month_text}</small></h3>

				<Table className="text-center" bordered>
					<thead>
						<tr>
							<th>Day</th>
							<th colSpan="2">Fajr</th>
							<th>Sunrise</th>
							<th colSpan="2">Dhuhr</th>
							<th colSpan="2">Asr</th>
							<th>Maghrib</th>
							<th colSpan="2">Isha</th>
							<th>Actions</th>
						</tr>
						<tr className="small_row">
							<th></th>
							<th className="small">Begin</th>
							<th className="small">Jammat</th>
							<th></th>
							<th className="small">Begin</th>
							<th className="small">Jammat</th>
							<th className="small">Begin</th>
							<th className="small">Jammat</th>
							<th></th>
							<th className="small">Begin</th>
							<th className="small">Jammat</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{times}
					</tbody>
				</Table>

			</div>
		);
	}

}

export default Timetable;
