import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Welcome extends Component {

	render() {
		return (
			<div className="Welcome container">
                <Redirect to={'/timetable'}/>
			</div>
		);
	}

}

export default Welcome;
