import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Welcome from './components/Welcome/Welcome';
import Timetable from './components/Timetable/Timetable';
import Login from './components/Login/Login';

const Routes = () => (
    <BrowserRouter >
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/timetable" component={Timetable} />
            <Route path="/" component={Welcome} />
        </Switch>
    </BrowserRouter>
);

export default Routes;