import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import logo from '../../logo.svg';
import './Login.css'
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			show_error: false,
			error_msg: '',
			redirect: false
		}
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		if(sessionStorage.getItem('ptdb-token')) {
			this.setState({
				redirect: true
			});
		}
	}

	login() {
		if(this.state.username && this.state.password) {
			axios({
				method: 'POST',
				url: 'https://api.prayertimedb.com/v1/login',
				data: {
					username: this.state.username,
					password: this.state.password
				}
			}).then((response) => {
				if(response.data.status === 'success') {
					sessionStorage.setItem('ptdb-user_id', response.data.data.user_id);
					sessionStorage.setItem('ptdb-token', response.data.data.token);
					sessionStorage.setItem('ptdb-org_prefix', response.data.data.org_prefix);
					this.setState({
						show_error: false,
						redirect: true
					});
				} else {
					this.setState({
						error_msg: 'Error: Username or password is incorrect.',
						show_error: true
					});
				}
			});
		} else {
			this.setState({
				error_msg: 'Error: Ensure you have entered username and password.',
				show_error: true
			});
		}
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			// console.log('enter press here! ')
			this.login();
		}
	}

	render() {
		if (this.state.redirect) {
			return (<Redirect to={'/timetable'}/>)
		}

		return (
			<Container className="Login">
				<img className="login-logo" src={logo} alt="Logo" />
				<Form className="form">
					<Col>
						<h3>Admin Login</h3>
						<FormGroup>
							<Label>Username</Label>
							<Input
								name="username"
								placeholder="username"
								onChange={this.onChange}
								onKeyPress={this.handleKeyPress}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="examplePassword">Password</Label>
							<Input
								type="password"
								name="password"
								placeholder="********"
								onChange={this.onChange}
								onKeyPress={this.handleKeyPress}
							/>
						</FormGroup>
						<Alert isOpen={this.state.show_error} color="danger">{this.state.error_msg}</Alert>
						<Button onClick={this.login}>Login</Button>
					</Col>
				</Form>
			</Container>
		);
	}

}

export default Login;
